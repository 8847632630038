import React, { useRef } from 'react'
import ab from '../Images/ab.png'
import resume from '../Files/resume.pdf'
import { motion, useScroll } from 'framer-motion'
import "./About.css"
import UpBotton from '../Components/UpBotton'
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/js/bootstrap.bundle.min';
import abBlack from '../Images/abBlack.png'
import { useTheme } from '../ThemeContext' 

function About() {

  const { isDarkMode } = useTheme();

  function MouseOver(event) {
    // eslint-disable-next-line
    {isDarkMode ? event.target.style.color = 'white' : event.target.style.color = 'black'}
  }
  function MouseOut(event) {
    event.target.style.color = 'grey'
  }

  const edcarref = useRef(null)
  const skillref = useRef(null)

  const {scrollYProgress} = useScroll({
    target:"edcarref,skillref",
    offset:['-1,1','0.35,1'] 
  })

  return (
    <div  className='container' id='about' >
      <div  className='row' style={{marginTop:"100px", marginBottom:"50px"}}>
        <motion.div className='col-lg-6 col-12 d-flex flex-column align-items-center justify-content-center'
          variants={{
            hidden:{x:-500, opacity:0},
            visible:{x:0, opacity:1}
          }}
          initial="hidden"  
          animate="visible"
          transition={{delay:1.3, duration:.5, type:"tween"}}>
          <h3 className='hola'>
              Hola !!
            </h3>
          <p className=' aboutDesc text-center m-4'>
            <strong>
            I'm an aspiring software developer from India with a strong passion for full-stack development. 
            I enjoy solving complex problems and turning ideas into reality. 
            My background is in MSc. Applied Physics, but my love for coding led me to software development. 
            I'm also a pianist and photographer.
            </strong>
          </p>


          <div className='aboutNav d-flex justify-content-evenly mt-3'>
            <motion.a href='#educationAndExperience' style={{textDecoration:"none"}} onMouseOver={MouseOver} onMouseOut={MouseOut} whileHover={{
              scale:1.1
            }}>
              <p style={{color:"grey", hover:{color:"black"}}}>
                <strong>Education</strong>
              </p>
            </motion.a>
            <motion.a href='#educationAndExperience' style={{textDecoration:"none"}} onMouseOver={MouseOver} onMouseOut={MouseOut} whileHover={{
              scale:1.1
            }}>
              <p style={{color:"grey"}}>
                <strong>Experience</strong>
              </p>
            </motion.a>
            <motion.a href='#skills' style={{textDecoration:"none"}} onMouseOver={MouseOver} onMouseOut={MouseOut} whileHover={{
              scale:1.1
            }}>
              <p style={{color:"grey"}}>
                <strong>Skills</strong>
              </p>
            </motion.a>
          </div>
          
          <motion.a href={resume} target="_blank" rel="noopener noreferrer" className='mt-3' download
            whileHover={{
              scale:1.1
            }}>
              {isDarkMode ? <button type="button" class="btn btn-outline-light" style={{fontSize:"12px", textDecoration:"none"}}>
              <strong>
                Download Resume
              </strong> 
            </button>: <button type="button" class="btn btn-outline-dark" style={{fontSize:"12px", textDecoration:"none"}}>
              <strong>
                Download Resume
              </strong> 
            </button> }  
          </motion.a>
  
        </motion.div>
        <motion.div className='imageDiv col-lg-6 col-sm-12 col-12 d-sm-flex flex-sm-row justify-content-sm-center'
          variants={{
            hidden:{x:700, opacity:0},
            visible:{x:0, opacity:1}
          }}
          initial='hidden'
          animate="visible"
          transition={{delay:1.3, duration:.5, type:"tween"}}
          whileHover={{scale:1.1}} >
            {isDarkMode ? <img className='pic' src={abBlack} alt='Paulson Mathew' /> : <img className='pic' src={ab} alt='Paulson Mathew' /> }

        </motion.div>
      </div>

      {/* Education & Experience */}
      <motion.div ref={edcarref} className='educareerDiv row d-flex justify-content-center align-items-center' 
        style={{
          scale:scrollYProgress,
          opacity:scrollYProgress
        }} 
        id="educationAndExperience">

        {/* Education */}
        <div className='col-lg-6 col-12 d-flex flex-column align-items-center justify-content-center' id='education'>
          <motion.h3
            style={{
              fontFamily:"Mooli",
              fontSize:"35px"
            }}
            variants={{
              hidden:{opacity:0},
              visible:{opacity:1}
            }}
            initial="hidden"
            animate="visible"
            transition={{duration:2.1}}
            whileInView="animate"
            viewport={{
              once:true
            }}
            >
              <strong>Education</strong> 
          </motion.h3>
          
          { isDarkMode ? <motion.div className='mt-5' style={{position:"relative",borderLeft:"solid 2px"}}
            variants={{
              hidden:{opacity:0,y:70},
              visible:{opacity:1,y:0}
            }}
            initial="hidden"
            animate="visible"
            transition={{duration:1}}
            >

            <div style={{width:"10px", height:"10px", borderRadius:"50%", backgroundColor:"white",display:"block",position:"absolute",left:"-6px", top:"17px"}}></div>
              
              {/* MSc Applied Physics */}
              <div className='card-body ms-3 d-flex flex-column' style={{borderRadius:"5%", border:"outset", width:"250px",}}>
                <div className='ms-4 mt-3'>
                  <h5>Msc Applied Physics</h5>
                  <h5>2017-2019</h5>
                  <p>C.M.S College Kottayam</p>
                </div>
              </div>
          {/* </div> */}
          
          {/* <div className='' style={{position:"relative",borderLeft:"solid 2px"}}> */}
            <div style={{width:"10px", height:"10px", borderRadius:"50%", backgroundColor:"white",display:"block",position:"relative",left:"-6px", top:"17px"}}></div>
              
              {/* BSc Physics */}
              <div className='card-body ms-3 d-flex flex-column' style={{borderRadius:"5%", border:"outset",width:"250px"}}>
                <div className='ms-4 mt-3'>
                  <h5>BSc Physics</h5>
                  <h5>2013-2016</h5>
                  <p>C.M.S College Kottayam</p>
                </div>
              </div>
          </motion.div> : <motion.div className='mt-5' style={{position:"relative",borderLeft:"solid 2px"}}
            variants={{
              hidden:{opacity:0,y:70},
              visible:{opacity:1,y:0}
            }}
            initial="hidden"
            animate="visible"
            transition={{duration:1}}
            >

            <div style={{width:"10px", height:"10px", borderRadius:"50%", backgroundColor:"black",display:"block",position:"absolute",left:"-6px", top:"17px"}}></div>
              
              {/* MSc Applied Physics */}
              <div className='card-body ms-3 d-flex flex-column' style={{borderRadius:"5%", border:"outset", width:"250px",}}>
                <div className='ms-4 mt-3'>
                  <h5>Msc Applied Physics</h5>
                  <h5>2017-2019</h5>
                  <p>C.M.S College Kottayam</p>
                </div>
              </div>
          {/* </div> */}
          
          {/* <div className='' style={{position:"relative",borderLeft:"solid 2px"}}> */}
            <div style={{width:"10px", height:"10px", borderRadius:"50%", backgroundColor:"black",display:"block",position:"relative",left:"-6px", top:"17px"}}></div>
              
              {/* BSc Physics */}
              <div className='card-body ms-3 d-flex flex-column' style={{borderRadius:"5%", border:"outset",width:"250px"}}>
                <div className='ms-4 mt-3'>
                  <h5>BSc Physics</h5>
                  <h5>2013-2016</h5>
                  <p>C.M.S College Kottayam</p>
                </div>
              </div>
          </motion.div>
          } 
        </div>

        {/* Carrer */}
        <div className='col-lg-6 col-12 d-flex flex-column align-items-center justify-content-center mt-3' id='career' >
          <motion.h3
            style={{
              fontFamily:"Mooli",
              fontSize:"35px"
            }}
            variants={{
              hidden:{opacity:0},
              visible:{opacity:1}
            }}
            initial="hidden"
            animate="visible"
            transition={{duration:2.1}}
            >
              <strong>Experience</strong>
          </motion.h3>

          { isDarkMode ? <motion.div className='mt-5' style={{position:"relative",borderLeft:"solid 2px"}}
            variants={{
              hidden:{opacity:0,y:70},
              visible:{opacity:1,y:0}
            }}
            initial="hidden"
            animate="visible"
            transition={{duration:1}}
            >
            
            {/* Amazon */}
            <div style={{width:"10px", height:"10px", borderRadius:"50%", backgroundColor:"white",display:"block",position:"absolute",left:"-6px", top:"17px"}}></div>
              <div className='card-body ms-3' style={{borderRadius:"5%", border:"outset", width:"250px"}}>
                <div className='ms-4 mt-3'>
                  <h5>Investigation Specialist</h5>
                  <h5>Amazon</h5>
                  <p>2022-2022</p>
                </div>
              </div>

          {/* Hoverspaces */}
            <div style={{width:"10px", height:"10px", borderRadius:"50%", backgroundColor:"white",display:"block",position:"relative",left:"-6px", top:"17px"}}></div>
              <div className='card-body ms-3' style={{borderRadius:"5%", border:"outset",width:"250px"}}>
                <div className='ms-4 mt-3'>
                  <h5>Junior Frontend Developer</h5>
                  <h5>Hoverspaces</h5>
                  <p>2022-2023</p>
                </div>
              </div>
          </motion.div> : <motion.div className='mt-5' style={{position:"relative",borderLeft:"solid 2px"}}
            variants={{
              hidden:{opacity:0,y:70},
              visible:{opacity:1,y:0}
            }}
            initial="hidden"
            animate="visible"
            transition={{duration:1}}
            >

            {/* Amazon */}

            <div style={{width:"10px", height:"10px", borderRadius:"50%", backgroundColor:"black",display:"block",position:"absolute",left:"-6px", top:"17px"}}></div>
              <div className='card-body ms-3' style={{borderRadius:"5%", border:"outset", width:"250px"}}>
                <div className='ms-4 mt-3'>
                  <h5>Investigation Specialist</h5>
                  <h5>Amazon</h5>
                  <p>2022-2022</p>
                </div>
              </div>

          {/* Hoverspaces */}
          
            <div style={{width:"10px", height:"10px", borderRadius:"50%", backgroundColor:"black",display:"block",position:"relative",left:"-6px", top:"17px"}}></div>
              <div className='card-body ms-3' style={{borderRadius:"5%", border:"outset",width:"250px"}}>
                <div className='ms-4 mt-3'>
                  <h5>Junior Frontend Developer</h5>
                  <h5>Hoverspaces</h5>
                  <p>2022-2023</p>
                </div>
              </div>
          </motion.div>
          }
          </div>
        </motion.div>

      {/* Skills */}
      <motion.div ref={skillref} style={{
        scale:scrollYProgress,
        opacity:scrollYProgress
      }} className='row skillsDiv' id="skills">
        <motion.h3 className='d-flex align-items-center justify-content-center'
          style={{
            fontFamily:"Mooli",
            fontSize:"35px"
          }}
          variants={{
            hidden:{opacity:0},
            visible:{opacity:1}
          }}
          initial="hidden"
          animate={{ opacity:5}}
          transition={{duration:2.1}}
          >
            <strong>Skills</strong>
        </motion.h3>

        <motion.div className='skills circular-progress-bar mt-5'
          variants={{
            hidden:{opacity:0,y:70},
            visible:{opacity:1,y:0}
          }}
          initial="hidden"
          animate="visible"
          transition={{duration:1}}
          >
          <div className='row '>
            <div className='col-lg-2'></div>

            {/* Mean */}

            { isDarkMode ? <div className='mean col-lg-2 col-5 d-flex align-items-center justify-content-center'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(white 306deg, #363636 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"black"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>MEAN</strong></span>
              </motion.div>
            </div> : <div className='mean col-lg-2 col-5 d-flex align-items-center justify-content-center'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(black 306deg, grey 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"white"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>MEAN</strong></span>
              </motion.div>
            </div> 
            }
            
            {/* Mern */}

            { isDarkMode ? <div className='mern col-lg-2 col-5 d-flex align-items-center justify-content-center'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(white 288deg, #363636 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"black"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>MERN</strong></span>
              </motion.div>
            </div> : <div className='mern col-lg-2 col-5 d-flex align-items-center justify-content-center'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(black 288deg, grey 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"white"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>MERN</strong></span>
              </motion.div>
            </div>
            }

            {/* Python */}

            { isDarkMode ? <div className='python col-lg-2 mt-3 mb-lg-3 col-5 d-flex align-items-center justify-content-center'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(white 252deg, #363636 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"black"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>Python</strong></span>
              </motion.div>
            </div> : <div className='python col-lg-2 mt-3 mb-lg-3 col-5 d-flex align-items-center justify-content-center'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(black 252deg, grey 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"white"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>Python</strong></span>
              </motion.div>
            </div>
            }
            
            {/* sql */}

            { isDarkMode ? <div className='sql col-lg-2 mt-3 mb-lg-3 col-5 d-flex align-items-center justify-content-center'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(white 216deg, #363636 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"black"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>SQL</strong></span>
              </motion.div>
            </div> : <div className='sql col-lg-2 mt-3 mb-lg-3 col-5 d-flex align-items-center justify-content-center'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(black 216deg, grey 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"white"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>SQL</strong></span>
              </motion.div>
            </div>
            }

            <div className='col-lg-4 mt-lg-5'></div>

            {/* django */}

            { isDarkMode ? <div className='django col-lg-2 mt-3 mb-lg-3 col-5 d-flex align-items-center justify-content-center mt-lg-5'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(white 216deg, #363636 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"black"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>Django</strong></span>
              </motion.div>
            </div> : <div className='django col-lg-2 mt-3 mb-lg-3 col-5 d-flex align-items-center justify-content-center mt-lg-5'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(black 216deg, grey 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"white"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>Django</strong></span>
              </motion.div>
            </div>
            }
            
            {/* figma */}

            { isDarkMode ? <div className='figma col-lg-2 mt-3 mb-lg-3 col-5 d-flex align-items-center justify-content-center mt-lg-5'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(white 180deg, #363636 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"black"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>Figma</strong></span>
              </motion.div>
            </div> : <div className='figma col-lg-2 mt-3 mb-lg-3 col-5 d-flex align-items-center justify-content-center mt-lg-5'>
              <motion.div className='circular-progress' style={{position:"relative",height:"120px", width:"120px",borderRadius:"50%",background:"conic-gradient(black 180deg, grey 0deg)",display:"flex",alignItems:"center",justifyContent:"center"}} whileHover={{scale:1.1}}>
                <div style={{content:'',position:"absolute",height:'85px', width:"85px", borderRadius:"50%",background:"white"}}></div>
                <span className='progress-value' style={{position:"absolute"}}><strong>Figma</strong></span>
              </motion.div>
            </div>
            }
          </div>
        </motion.div>
      </motion.div>
    <UpBotton/>
    </div>
  )
}

export default About