import { motion } from 'framer-motion'
import React from 'react'
import bsharp from '../Images/bsharpimg.png'
//import bsharplogo from '../Images/bsharplogo.png'
import { Link } from 'react-router-dom'
import burgerhouse from '../Images/burgerhouse.png'
import { useTheme } from '../ThemeContext'
import biryaniHut from '../Images/biryaniHut.png'

function Portfolio() {

  const {isDarkMode} = useTheme()

  return (
    <>
    <div className='row'>
      <div className='col-lg-3'></div>
      <div className='col-lg-3 col-12 mt-5 d-flex justify-content-center'>
        { isDarkMode ? <motion.div class="card bg-dark" style={{width:"21rem"}}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{delay:1.3, duration:1.5}}
        >
          <div className='d-flex justify-content-center'>
            <img src={bsharp} class="d-flex justify-content-center mt-3" alt="..." style={{width:"250px",height:"200px"}}/>
          </div>
          <div class="card-body" >
            <h5 class="card-title text-light"><strong>B-Sharp Music Conservatory</strong></h5>
            <p class="card-text text-light">B-Sharp Music Conservatory is a web application for exploring and learning music.</p>
            <div className=''>
              <Link to='https://github.com/ajoyscorpion/bsharp-frontend' target="_blank" className='text-light ms-3'>
                <i class="fa-brands fa-github fa-xl"></i>
              </Link>
              <Link to="https://bsharp-music.netlify.app/" target="_blank" className='text-light ms-3'>
                <i class="fa-solid fa-link fa-xl" ></i>
              </Link>
              <Link to="https://github.com/ajoyscorpion/bsharp-server" target="_blank" className='text-light ms-3'>
                <i class="fa-solid fa-server fa-xl"></i>
              </Link>
            </div>
            <div className='mt-3 row'>
              <div className='col-3'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>React</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>MongoDB</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Express</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Node.js</p>
              </div>
            </div>
          </div>
        </motion.div> : <motion.div class="card" style={{width:"21rem"}}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{delay:1.3, duration:1.5}}
        >
          <div className='d-flex justify-content-center'>
            <img src={bsharp} class="d-flex justify-content-center mt-3" alt="..." style={{width:"250px",height:"200px"}}/>
          </div>
          <div class="card-body" >
            <h5 class="card-title"><strong>B-Sharp Music Conservatory</strong></h5>
            <p class="card-text">B-Sharp Music Conservatory is a web application for exploring and learning music.</p>
            <div className=''>
              <Link to='https://github.com/ajoyscorpion/bsharp-frontend' target="_blank" className='text-dark ms-3'>
                <i class="fa-brands fa-github fa-xl" style={{color: "#000000;"}}></i>
              </Link>
              <Link to="https://bsharp-music.netlify.app/" target="_blank" className='text-dark ms-3'>
                <i class="fa-solid fa-link fa-xl" style={{color: "#000000;"}}></i>
              </Link>
              <Link to="https://github.com/ajoyscorpion/bsharp-server" target="_blank" className='text-dark ms-3'>
                <i class="fa-solid fa-server fa-xl"></i>
              </Link>
            </div>
            <div className='mt-3 row'>
              <div className='col-3'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>React</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>MongoDB</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Express</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Node.js</p>
              </div>
            </div>
          </div>
        </motion.div>
        }
        
      </div>
      <div className='col-lg-3 col-12 mt-5 d-flex justify-content-center'>
        { isDarkMode ? <motion.div class="card bg-dark" style={{width:"21rem"}}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{delay:1.3, duration:1.5}}
        >
        <div className='d-flex justify-content-center'>
            <img src={burgerhouse} class="d-flex justify-content-center mt-3" alt="..." style={{width:"250px",height:"200px"}}/>
          </div>
          <div class="card-body" >
            <h5 class="card-title text-light"><strong>Burger House</strong></h5>
            <p class="card-text text-light">Burger House is a web application that allows users to explore a variety of burgers and place orders.</p>
            <div className=''>
              <Link to='https://github.com/ajoyscorpion/burger-house-frontend' target="_blank" className='text-light ms-3'>
                <i class="fa-brands fa-github fa-xl"></i>
              </Link>
              <Link to="https://burger-house-restaurant.netlify.app/" target="_blank" className='text-light ms-3'>
                <i class="fa-solid fa-link fa-xl"></i>
              </Link>
              <Link to="https://github.com/ajoyscorpion/burger-house-server" target="_blank" className='text-light ms-3'>
                <i class="fa-solid fa-server fa-xl"></i>
              </Link>
            </div>
            <div className='mt-3 row'>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Angular</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>MongoDB</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Express</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Node.js</p>
              </div>
            </div>
          </div>
        </motion.div> : <motion.div class="card" style={{width:"21rem"}}
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{delay:1.3, duration:1.5}}
        >
        <div className='d-flex justify-content-center'>
            <img src={burgerhouse} class="d-flex justify-content-center mt-3" alt="..." style={{width:"250px",height:"200px"}}/>
          </div>
          <div class="card-body" >
            <h5 class="card-title"><strong>Burger House</strong></h5>
            <p class="card-text">Burger House is a web application that allows users to explore a variety of burgers and place orders.</p>
            <div className=''>
              <Link to='https://github.com/ajoyscorpion/burger-house-frontend' target="_blank" className='text-dark ms-3'>
                <i class="fa-brands fa-github fa-xl" style={{color: "#000000;"}}></i>
              </Link>
              <Link to="https://burger-house-restaurant.netlify.app/" target="_blank" className='text-dark ms-3'>
                <i class="fa-solid fa-link fa-xl" style={{color: "#000000;"}}></i>
              </Link>
              <Link to="https://github.com/ajoyscorpion/burger-house-server" target="_blank" className='text-dark ms-3'>
                <i class="fa-solid fa-server fa-xl"></i>
              </Link>
            </div>
            <div className='mt-3 row'>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Angular</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>MongoDB</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Express</p>
              </div>
              <div className='col-4'>
                <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Node.js</p>
              </div>
            </div>
          </div>
        </motion.div>
       }  
      </div>
      <div className='col-lg-3'></div>
    </div>

    <div className='row'>
    <div className='col-lg-3'></div>
    <div className='col-lg-3 col-12 mt-5 d-flex justify-content-center'>
      { isDarkMode ? <motion.div class="card bg-dark" style={{width:"21rem"}}
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{delay:1.3, duration:1.5}}
      >
        <div className='d-flex justify-content-center'>
          <img src={biryaniHut} class="d-flex justify-content-center mt-3" alt="..." style={{width:"250px",height:"200px"}}/>
        </div>
        <div class="card-body" >
          <h5 class="card-title text-light"><strong>Biryani Hut Admin</strong></h5>
          <p class="card-text text-light">Biryani Hut is a web application that allows server to place orders according to the assigned tables in a restaurant.</p>
          <div className=''>
            <Link to='https://github.com/ajoyscorpion/biryanihut_frontend' target="_blank" className='text-light ms-3'>
              <i class="fa-brands fa-github fa-xl"></i>
            </Link>
            <Link to="https://biryanihut-server.netlify.app/" target="_blank" className='text-light ms-3'>
              <i class="fa-solid fa-link fa-xl" ></i>
            </Link>
            <Link to="https://github.com/ajoyscorpion/biryanihut_server" target="_blank" className='text-light ms-3'>
              <i class="fa-solid fa-server fa-xl"></i>
            </Link>
          </div>
          <div className='mt-3 row'>
            <div className='col-4'>
              <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Angular</p>
            </div>
            <div className='col-4'>
              <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>MongoDB</p>
            </div>
            <div className='col-4'>
              <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Express</p>
            </div>
            <div className='col-4'>
              <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Node.js</p>
            </div>
          </div>
        </div>
      </motion.div> : <motion.div class="card" style={{width:"21rem"}}
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{delay:1.3, duration:1.5}}
      >
        <div className='d-flex justify-content-center'>
          <img src={biryaniHut} class="d-flex justify-content-center mt-3" alt="..." style={{width:"250px",height:"200px"}}/>
        </div>
        <div class="card-body" >
          <h5 class="card-title"><strong>Biryani Hut Admin</strong></h5>
          <p class="card-text">Biryani Hut is a web application that allows server to place orders according to the assigned tables in a restaurant.</p>
          <div className=''>
            <Link to='https://github.com/ajoyscorpion/biryanihut_frontend' target="_blank" className='text-dark ms-3'>
              <i class="fa-brands fa-github fa-xl" style={{color: "#000000;"}}></i>
            </Link>
            <Link to="https://biryanihut-server.netlify.app/" target="_blank" className='text-dark ms-3'>
              <i class="fa-solid fa-link fa-xl" style={{color: "#000000;"}}></i>
            </Link>
            <Link to="https://github.com/ajoyscorpion/biryanihut_server" target="_blank" className='text-dark ms-3'>
              <i class="fa-solid fa-server fa-xl"></i>
            </Link>
          </div>
          <div className='mt-3 row'>
            <div className='col-4'>
              <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Angular</p>
            </div>
            <div className='col-4'>
              <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>MongoDB</p>
            </div>
            <div className='col-4'>
              <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Express</p>
            </div>
            <div className='col-4'>
              <p className='bg-secondary text-white rounded-5 d-flex justify-content-center'>Node.js</p>
            </div>
          </div>
        </div>
      </motion.div>
      }
      
    </div>
    <div className='col-lg-3 col-12 mt-5 d-flex justify-content-center'>
    </div>
    <div className='col-lg-3'></div>
    </div>

    </>
  )
}

export default Portfolio




/* <motion.div 
  initial={{opacity:0}}
  animate={{opacity:1}}
  transition={{delay:1.3, duration:.5}}
  className='col-lg-12 d-flex justify-content-center align-items-center' style={{width:"100%", height:"70vh"}}>
    <h2><strong>Under <span style={{color:"#862B0D"}}>Construction</span> !!!</strong></h2>
</motion.div> */