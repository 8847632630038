import { motion } from 'framer-motion'
import React from 'react'
import ab from '../Images/ab.png'
import { useTheme } from '../ThemeContext'
import './Home.css'
import abBlack from '../Images/abBlack.png'

function Home() {

  const { isDarkMode } = useTheme();

  return (
    // Home
    <div className='container'>
      <div className='row' style={{marginTop:"100px", marginBottom:"72px"}}>
        <motion.div className='col-12 col-lg-6 col-sm-12 d-flex flex-column align-items-center justify-content-center'
        initial={{x:-500, opacity:0}}
        animate={{x:0, opacity:1}}
        transition={{delay:1.3, duration:.5, type:"tween"}}>

          <div className='d-flex'>
            <div className='d-flex flex-column justify-content-center'>
              <h3 className='name'><strong>&lt; Paulson</strong></h3>
              <h3 className='name' id='lname'><strong>Mathew /&gt;</strong></h3>
            </div>
            { isDarkMode ? <div className='ms-3' style={{width:"5px",backgroundColor:'white',height:"170px"}}></div> : <div className='ms-3' style={{width:"5px",backgroundColor:'black',height:"170px"}}></div>}
            <div className='mt-3 ms-3 d-flex flex-column justify-content-center'>
              <p className='desc'><strong>Full Stack Developer</strong></p>
              <p className='desc'><strong>Tech Enthusiast</strong></p>
              <p className='desc'><strong>Junior Data Scientist</strong></p>
            </div>
          </div>
          
        </motion.div>
        <motion.div className='col-12 col-lg-6 col-sm-12 col-12 d-sm-flex flex-sm-row justify-content-sm-center'
          initial={{x:700, opacity:0}}
          animate={{x:0, opacity:1}}
          transition={{delay:1.3, duration:.5, type:"spring", stiffness:70}}
          whileHover={{scale:1.1}} 
          >
            {isDarkMode ? <img className='pic' src={abBlack} alt='Paulson Mathew' /> : <img className='pic' src={ab} alt='Paulson Mathew' /> }
          
        </motion.div>
      </div>
    </div>
  )
}

export default Home