import { motion } from 'framer-motion'
import React, {useRef } from 'react'
import { Link } from 'react-router-dom'
import ab from '../Images/ab.png'
import './Contact.css'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '../ThemeContext'
import abBlack from '../Images/abBlack.png'

function Contact() {

  const { isDarkMode } = useTheme();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_j75jh2f', 'template_3mju3zp', form.current, 'a_A_N1UZqW2tHyAHE')
      .then((result) => {
          console.log(result.text);
          toast.success("Message Sent Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
          e.target.reset();
      }, (error) => {
          console.log(error.text);
          toast.error("Error Sending Message")
      });
  };
  
  // const [phoneHovered, setPhoneHovered] = useState(false)

  // const [emailHovered, setEmailHovered] = useState(false)

  // const phoneMouseEnter = () => {
  //   setPhoneHovered(true);
  // }

  // const phoneMouseExit = () => {
  //   setPhoneHovered(false)
  // }

  // const emailMouseEnter = () => {
  //   setEmailHovered(true);
  // }

  // const emailMouseExit = () => {
  //   setEmailHovered(false)
  // }


  return (
    <div className='container mb-3' style={{ marginTop:"100px"}}>
      <div className='row'>
        <motion.div className='col-lg-6 col-sm-12 col-12 d-flex flex-column align-items-center justify-content-center'
        initial={{x:-500, opacity:0}}
        animate={{x:0, opacity:1}}
        transition={{delay:1.3, duration:.5, type:"tween"}}>
          {/* Contact Caption */}
          <div className="d-flex flex-column align-items-center justify-content-center">
            <h3 style={{fontFamily:'Pacifico',fontSize:"27px"}}>
              <strong>
                "Hit Me Up!"
              </strong>
            </h3>
            <p className='mt-3 contactDesc'>
              <strong>
                Multilingual mastery, but let's converse in English!
              </strong>
            </p>
          </div>  

          <motion.div className='row'
            whileHover={{
              scale:1.1, 
            }}>
            <div className='col-lg-2 col-1'></div>
            <div className='col-lg-8 col-10'>
              {isDarkMode ? 
              <div className='card mt-3' style={{ borderRadius:"7%", borderColor:"black", background:"#363636"}}>
                <div className='card-body'>
                  <form ref={form} onSubmit={sendEmail}>
                  <div class="row mb-3">
                    <div class="col">
                      <input type="text" name="from_name" class="form-control" placeholder="Name" aria-label="Name"/>
                    </div>
                    <div class="col">
                      <input type="email" name='from_email' class="form-control" placeholder="Email" aria-label="Email"/>
                    </div>
                  </div>
                  {/* message */}
                  <div class="mb-3">
                    <textarea class="form-control" name='message' id="Message" rows="5" placeholder="Message"></textarea>
                  </div>
                  {/* button - send */}
                  <motion.div className="button d-flex justify-content-center"
                    whileHover={{
                    scale:1.1 
                    }}>
                    <button type="submit" value="send" class="btn btn-outline-light" style={{width:'100px'}}><strong>Send</strong></button>  
                  </motion.div>
                  </form>
                </div> 
              </div> : <div className='card mt-3' style={{ borderRadius:"7%"}}>
                <div className='card-body'>
                  <form ref={form} onSubmit={sendEmail}>
                  <div class="row mb-3">
                    <div class="col">
                      <input type="text" name="from_name" class="form-control" placeholder="Name" aria-label="Name"/>
                    </div>
                    <div class="col">
                      <input type="email" name='from_email' class="form-control" placeholder="Email" aria-label="Email"/>
                    </div>
                  </div>
                  {/* message */}
                  <div class="mb-3">
                    <textarea class="form-control" name='message' id="Message" rows="5" placeholder="Message"></textarea>
                  </div>
                  {/* button - send */}
                  <motion.div className="button d-flex justify-content-center"
                    whileHover={{
                    scale:1.1 
                    }}>
                    <button type="submit" value="send" class="btn btn-outline-dark" style={{width:'100px'}}><strong>Send</strong></button>  
                  </motion.div>
                  </form>
                </div> 
              </div>
              }
            </div>
            <div className='col-lg-2 col-1'></div>
          </motion.div>


        </motion.div>
        <motion.div className='imageDiv col-lg-6 col-sm-12 col-12 d-flex align-items-center justify-content-center'
          initial={{x:700, opacity:0}}
          animate={{x:0, opacity:1}}
          transition={{delay:1.3, duration:.5, type:"spring", stiffness:70}}
          whileHover={{scale:1.1}} 
          >
            {isDarkMode ? <img className='pic' src={abBlack} alt='Paulson Mathew' /> : <img className='pic' src={ab} alt='Paulson Mathew' /> }
        </motion.div>
      </div>

      <div className='row mt-5' style={{marginBottom:""}}>           
      <motion.div className='col-lg-12 col-12 col-sm-12'
        initial={{y:500,opacity:0}}
        animate={{y:0, opacity:1}}
        transition={{delay:.7, duration:.5}}>
         {/* Social Media Icons */}
         <div className="d-flex align-items-center justify-content-center">
            <motion.div className='whatsapp ms-4'
              whileHover={{
                scale:1.5, 
              }}>
              <Link to="whatsapp://send?text=Hello World!&phone=+918113000923" target="_blank">
                {isDarkMode ? <i class="fa-brands fa-whatsapp fa-xl" style={{color:"#ffffff"}}></i> : <i class="fa-brands fa-whatsapp fa-xl" style={{color:"#000000"}}></i> }
              </Link>
            </motion.div>
            <motion.div className='instagram ms-4'
              whileHover={{
                scale:1.5, 
              }}>
              <Link to='https://instagram.com/paulson_mathew_3?igshid=NzZlODBkYWE4Ng%3D%3D&utm_source=qr' target="_blank">
                {isDarkMode ? <i class="fa-brands fa-instagram fa-xl" style={{color:"#ffffff"}}></i> : <i class="fa-brands fa-instagram fa-xl" style={{color:"#000000"}}></i>}
                
              </Link>
            </motion.div>
            <motion.div className='github ms-4'
              whileHover={{
                scale:1.5, 
              }}>
              <Link to='https://github.com/ajoyscorpion' target="_blank">
                {isDarkMode ? <i class="fa-brands fa-github fa-xl" style={{color:"#ffffff"}}></i> : <i class="fa-brands fa-github fa-xl" style={{color:"#000000"}}></i>}
              </Link>
            </motion.div>
            <motion.div className='linkedIn ms-4'
              whileHover={{
                scale:1.5, 
              }}>
              <Link to='https://www.linkedin.com/in/paulson-mathew-067641149/' target="_blank">
                {isDarkMode ? <i class="fa-brands fa-linkedin fa-xl" style={{color:"#ffffff"}}></i> : <i class="fa-brands fa-linkedin fa-xl" style={{color:"#000000"}}></i>}
                
              </Link>
            </motion.div>
          </div>
          <div className='row'>
            <div className='col-12 mt-3 d-flex flex-column align-items-center justify-content-center'>
              <p>
                <strong>paulsonmathew11@gmail.com</strong>
              </p>
            </div>
            <div className='col-12 d-flex  align-items-center justify-content-center'>
              <p>
                <strong>+91 8113000923</strong>
              </p>
            </div>
          </div>
      </motion.div>
      </div>
      <ToastContainer /> 
    </div>
  )
}

export default Contact