import { motion } from 'framer-motion'
import React from 'react'


function Footer() {
  return (
    <motion.div style={{display:"flex",justifyContent:"center",marginTop:"70px"}}
      initial={{y:100}}
      animate={{y:0}}
      transition={{delay:.7, duration:.5}}
    >
        <p> &copy; <span style={{fontFamily:"Libre Barcode 128"}}>Paulson Mathew</span> </p>
    </motion.div>
  )
}

export default Footer 