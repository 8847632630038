import { motion } from 'framer-motion'
import React from 'react'
import { Link } from 'react-router-dom'
import pic from '../Images/logo.png'
import { useTheme } from '../ThemeContext'
import logoBlack from '../Images/logoBlack.png'


function Header() {

    const { isDarkMode, toggleTheme } = useTheme();

  return (
    // Navigation Bar
    <motion.div className='Navbar'
        initial={{y:-300}}
        animate={{y:0}}
        transition={{delay:.7, duration:.5}}
        >
        <div className='row pt-5'>
            <div className="col-lg-2 col-sm-2 col-1"></div>

            {/* Logo */}
            <motion.div className="col-lg-2 col-sm-2 col-2" id="logo" whileHover={{scale:1.01}}>
                <Link to='/'>
                    {isDarkMode ? <img src={logoBlack} width="75px" alt='logo'></img> : <img src={pic} width="75px" alt='logo'></img> }   
                </Link>
            </motion.div>

            <div className='col-lg-3 col-sm-3 col-3'></div>

            {/* Navigation Items */}
            <div className="col-lg-3 col-sm-3 col-3 d-flex align-items-center justify-content-evenly">
                <nav class="navbar navbar-expand-lg">
                    {isDarkMode ? <button class="navbar-toggler btn-light" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#navbarNav" 
                                aria-controls="navbarNav" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation" 
                                data-bs-auto-close="true">
                        <span class="navbar-toggler-icon bg-light rounded"></span>
                    </button> : <button class="navbar-toggler" 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target="#navbarNav" 
                                aria-controls="navbarNav" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation" 
                                data-bs-auto-close="true">
                        <span class="navbar-toggler-icon"></span>
                    </button>}
        
                    <div class="collapse navbar-collapse" id="navbarNav" >
                       <ul class="navbar-nav" >
                            <li class="nav-item">
                                {isDarkMode ? <Link to='/about' class="nav-link text-light" href="About">
                                    <strong>About</strong></Link> 
                                    : <Link to='/about' class="nav-link" href="About" color='white'><strong>About</strong></Link> 
                                }
                            </li>
                            <li class="nav-item">
                                {isDarkMode ? <Link to='/portfolio' class="nav-link text-light" href="Portfolio">
                                    <strong>Portfolio</strong></Link> 
                                    : <Link to='/portfolio' class="nav-link" href="Portfolio"><strong>Portfolio</strong></Link> 
                                }
                            </li>
                            <li class="nav-item">
                                {isDarkMode ? <Link to='/contact' class="nav-link text-light" href="Contact">
                                    <strong>Contact</strong></Link> 
                                    : <Link to='/contact' class="nav-link" href="Contact"><strong>Contact</strong></Link> 
                                }
                            <Link to="" class="nav-link" href="Contact">
                                <strong></strong>
                            </Link>
                            </li>   
                        </ul>
                    </div>   
                </nav>
            </div>

            {/* dark/light theme */}
            <div className='col-lg-1 col-sm-1 col-1 d-flex align-items-center'>
                {isDarkMode ? <button className='btn' type='' style={{border:"none"}} onClick={toggleTheme}> 
                    <i class="fa-regular fa-sun fa-lg" style={{color: "#ffffff;"}}></i>
                    </button> : < button className='btn' type='' style={{border:"none"}} onClick={toggleTheme}><i class="fa-regular fa-moon fa-lg" style={{color: "#000000;"}}></i> 
                    </button>
                }         
            </div>

            <div className='col-lg-1 col-sm-1 col-1'></div>
        </div>
    </motion.div>
  )
}

export default Header