
import './App.css';
import {Routes,Route} from 'react-router-dom'
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Portfolio from './Pages/Portfolio';

function App() {
  return (
      <div className='App'>
          {/* header */}
        <header>
          <Header></Header>
        </header>  

        <Routes>
          <Route path="/" Component={Home}/>
          <Route path="/About" Component={About}/>
          <Route path="/Contact" Component={Contact}/>
          <Route path="/Portfolio" Component={Portfolio}/>
        </Routes>

        {/* footer */}
        <footer>
          <Footer></Footer>
        </footer>
      </div>
  );
}

export default App;
