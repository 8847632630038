import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react'

function UpBotton() {

    const[up,setUp] = useState(false);
    
    useEffect(()=>{
        window.addEventListener("scroll",()=>{
            if(window.scrollY > 100){
                setUp(true)
            }
            else{
                setUp(false)
            }
        })
    },[])

    const scrollTo = () =>{
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }
  return (
    <div>
        {up && (
            <motion.button
            class=""
            style={{
                position:"fixed",
                bottom:"50px",
                right:"50px",
                height:"50px",
                width:"50px",
                borderRadius:"50%",
                backgroundColor:"white",
                border:"none"
                
            }} 
            onClick={scrollTo}
            whileHover={{
                scale:1.5, 
              }}>
                <i class="fa-solid fa-caret-up fa-2xl" style={{color: "#000000"}}></i>    
            </motion.button>
        )}
    </div>
  )
}

export default UpBotton